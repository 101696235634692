
import { Component, Prop, Watch } from 'vue-property-decorator'
import { EmojiPicker } from 'vue-emoji-picker'
import VueStrong from '@/VueStrong'
@Component({ components: { EmojiPicker } })
export default class TemplateInput extends VueStrong {
  @Prop({ default: 'text-field' }) kind: 'text-field' | 'text-area'
  @Prop({ required: true }) value: string
  @Prop({ default: '' }) label: string
  @Prop({ default: false }) onlyLink: boolean
  @Prop({ default: '' }) rules: [(v: string) => boolean | string]

  showButtons = false
  // updatedValue = ''

  get updatedValue(): string {
    return this.value
  }

  set updatedValue(newValue: string) {
    this.$emit('input', newValue)
  }

  // created() {
  //   this.updatedValue = this.value
  // }

  insert(emoji) {
    this.updatedValue += emoji
  }

  // @Watch('value')
  // updateValue() {
  //   this.updatedValue = this.value
  // }
}
