
import VueStrong from '@/VueStrong'
import { Component, Prop } from 'vue-property-decorator'

@Component
export default class TemplateCardAdCreative extends VueStrong {
  @Prop({ required: true }) template: yakkyo.IFacebookAdTemplateAdCreative | any
  @Prop({ default: false }) disableActions: boolean
  expand = false
  expanded = [0]
  get breakpoint() {
    return this.$vuetify.breakpoint
  }
  get templateVariations() {
    const templateVar = this.template.generalVariations.flatMap(general => {
      const link = general.link
      const title = general.title
      const description = general.description
      const body = general.body
      const urlTags = this.template.urlTagsVariations.map(urlTag => urlTag)
      return urlTags.map(urlTag => ({ name: this.template.name, link, title, description, body, urlTags: urlTag }))
    })
    return templateVar
  }
}
