
import { Component, Prop } from 'vue-property-decorator'
import VueStrong from '@/VueStrong'

@Component
export default class TemplateCardCampaign extends VueStrong {
  @Prop({ required: true }) template: yakkyo.IFacebookAdTemplate
  @Prop({ default: false }) disableActions: boolean

  get objectiveIcon() {
    switch (this.template.campaign.objective) {
      case 'OUTCOME_TRAFFIC':
        return 'mdi-link-box'
      case 'OUTCOME_ENGAGEMENT':
        return 'mdi-account-group'
      case 'OUTCOME_LEADS':
        return 'mdi-notebook-edit'
      case 'OUTCOME_SALES':
        return 'mdi-cart'
      default:
        return ''
    }
  }

  get budgetData() {
    return [
      {
        budget: this.template.campaign.budget,
        budgetWindow: this.template.campaign.budgetWindow,
        bidStrategy: this.template.campaign.bid_strategy.toLowerCase().split('_').join(' ')
      }
    ]
  }
}
