
import VueStrong from '@/VueStrong'
import { combineAdsetVariations } from '@/helpers/utils'
import { Component, Prop } from 'vue-property-decorator'

@Component
export default class TemplateCardAdSet extends VueStrong {
  @Prop({ required: true }) template: any
  @Prop({ default: false }) disableActions: boolean
  expand = false

  get budgetData() {
    return [
      {
        budget: this.template.budget,
        budgetWindow: this.template.budgetWindow,
        bidAmount: this.template.bid_amount,
        bidStrategy: this.template.bid_strategy.toLowerCase().split('_').join(' ')
      }
    ]
  }

  get allAdsetVariations() {
    const combinedVariations = combineAdsetVariations(this.template.variations)
    return combinedVariations
  }
  get showCountries() {
    return (
      this.template.targeting &&
      this.template.targeting.geo_locations &&
      this.template.targeting.geo_locations.countries &&
      this.template.targeting.geo_locations.countries.length
    )
  }

  get genders() {
    const res = { male: false, female: false }
    if (this.template.targeting.genders.includes(1)) res.male = true
    if (this.template.targeting.genders.includes(2)) res.female = true
    return { male: res.male, female: res.female }
  }
}
